var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Online Course SEO "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{ref:"form",staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select Category","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"type","options":_vm.categoriesList,"reduce":function (val) { return val.id; }},on:{"input":function($event){return _vm.getCoursesByCategory(_vm.addCourseForm.category)}},model:{value:(_vm.addCourseForm.category),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "category", $$v)},expression:"addCourseForm.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select Course","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.coursesList,"reduce":function (val) { return val; }},on:{"input":function($event){return _vm.getDuration(_vm.addCourseForm.course)}},scopedSlots:_vm._u([(!_vm.getCourse)?{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Select Category First ... ")]}}:{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Loading ... ")]}}],null,true),model:{value:(_vm.addCourseForm.course),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "course", $$v)},expression:"addCourseForm.course"}},[_vm._v(" > ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Title","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addCourseForm.title),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "title", $$v)},expression:"addCourseForm.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Description","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addCourseForm.description),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "description", $$v)},expression:"addCourseForm.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Keywords","label-for":"blog-content"}},[_c('b-form-tags',{model:{value:(_vm.addCourseForm.keyword),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "keyword", $$v)},expression:"addCourseForm.keyword"}})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[(_vm.isAdd)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" add ")]):_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" update ")])],1)],1)],1)],1),_c('b-row',[_c('seo-online-list',{ref:"table"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }